import { DefaultSeoProps } from "next-seo";
import appConfig from "./config";

const { company, description } = appConfig;

const config: DefaultSeoProps = {
  defaultTitle: company,
  titleTemplate: `%s | ${company}`,
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://avaprotocol.org",
    siteName: "Ava Protocol",
    title: company,
    description: description,
    images: [
      {
        url: "/image/logo-light-horizontal.png",
        width: 271,
        height: 40,
        alt: `${company} Logo`,
      },
    ],
  },
  twitter: {
    handle: "@ava_protocol",
    site: "@ava_protocol",
    cardType: "summary_large_image",
  },
};

export default config;
